<template>
    <div>
        <Header/>
        <div class=" v_content">
        <div style="padding:15px;">
            <div class="justify-content-md-center" style="max-width: 150vh;margin: auto;">
                <div style="text-align:left;margin-top:20px;">
                    <h5><strong>{{$t('header_history')}}</strong></h5>
                </div>
                <div style="text-align: left;margin-top:30px;margin-bottom:20px;">
                    <div class="bt_not"  @click="selectType(0)" :class="selectActive(0)" style="margin-right:5px;">
                        {{$t('Hourly')}}
                    </div>
                    <div class="bt_not" @click="selectType(3)" :class="selectActive(3)" style="margin-right:5px;">
                        {{$t('Daily')}}
                    </div>
                    <div class="bt_not" @click="selectType(1)" :class="selectActive(1)" style="margin-right:5px;">
                        {{$t('Monthly')}}
                    </div>
                    <div class="bt_not" @click="selectType(2)" :class="selectActive(2)">
                        {{$t('Yearly')}}
                    </div>
                </div>
                <HistoryHr v-if="historyType === 0"/>
                <HistoryD v-if="historyType === 3"/>
                <HistoryM v-if="historyType === 1" :reportList="reportList"/>
                <HistoryY v-if="historyType === 2" :reportList="reportList"/>
            </div>
        </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '../containers/Header'
import Footer from '../containers/Footer'
import HistoryHr from '../containers/HistoryHr.vue'
import HistoryD from '../containers/HistoryD.vue'
import HistoryM from '../containers/HistoryM.vue'
import HistoryY from '../containers/HistoryY.vue'

export default {
    name: 'history',
    components : {
        Header,
        Footer,
        HistoryHr,
        HistoryD,
        HistoryM,
        HistoryY
    },
    data () {
        return {
            historyType : 0,
            reportList:[],
        }
    },
    methods : {
        selectType(index){
            this.historyType = index
        },
        selectActive(index){
            if(index == this.historyType)
            return 'bt_active'

            return ''
        },
        getReportDatas(){
            fetch('http://air4thai.com/forweb/getReport_List.php').then(response => response.json())
            .then(data => {
                console.log('Group',data['group'])
                this.reportList = data['group']
            })
            .catch((err) => {
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    title: 'Error!',
                    text: err,
                    icon: 'error'
                })
            })
        }
    },
    created () {
        this.historyType = 0
        this.getReportDatas()
    }
    
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.bt_active{
    background: #016FB7;
    color: #FFFFFF;
}
.bt_not{
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 20px;
    width: 100px;
    display: inline-block;
    margin:0px;
    text-align: center;
    cursor: pointer;
}
</style>