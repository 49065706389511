<template>
    <div>
        <div class="data_group">
            <b-row>
                <b-col cols="12" sm="12" md="12" lg="6">
                    <!-- <div class="list_select"> -->
                        <!-- <label>{{$t('list')}} | </label> -->
                    <b-form-select 
                    v-model="select_region" 
                    @change="change_selectRegion()"
                    style="height:42px;margin-bottom:10px;">
                        <option :value="null"> {{ $i18n.locale === 'th' ? 'ทั่วประเทศ' : 'Nationwide' }}</option>
                        <option v-for="region, index in regions" :key="index" :value="region.regionID">
                            {{ $i18n.locale === 'th' ? region.nameTH : region.nameEN }}
                        </option>
                    </b-form-select>
                    <!-- </div> -->
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="6" style="text-align: left;">
                    <multiselect 
                    v-model="stationSelect" 
                    :options="stationList"
                    :multiple="true"
                    :taggable="true"
                    :close-on-select="false"
                    :hide-selected="true"
                    :searchable="true"
                    label="text"
                    track-by="text"
                    :placeholder="this.$i18n.locale === 'th' ? 'กรุณาเลือกสถานีที่ต้องการทราบข้อมูลย้อนหลัง' : 'Please select the station you want to know historical information.' " 
                    style="margin-top:0px;margin-bottom:10px;"
                    class="multi_style"
                    >
                        <!-- 
                        <template slot="tag" slot-scope="{ option , remove }">
                            <span class="custom__tag">
                                <span>{{ $i18n.locale === 'th' ?  option.text : option.textEN }}</span>
                                <span class="custom__remove" @click="remove(option)">❌</span>
                            </span>
                        </template> 
                        -->
                        <template slot="selection" slot-scope="{ values, isOpen }">
                            <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                                {{ $i18n.locale === 'th' ? 'เลือกอยู่ ' + values.length + ' สถานี' : values.length + ' stations selected' }} 
                            </span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <div class="option__desc"><span class="option__title">
                                {{ $i18n.locale === 'th' ?  props.option.text : props.option.textEN }}</span>
                            </div>
                        </template>
                    </multiselect>
                </b-col>
                <b-col cols="12" sm="12" md="12" style="text-align: left;">
                    <b-form-checkbox-group
                        id="checkbox-group"
                        v-model="selectedType"
                        name="selectType"
                        style="margin-top:0px;"
                    >
                        <b-form-checkbox value="PM25">PM<sub>2.5</sub></b-form-checkbox>
                        <b-form-checkbox value="PM10">PM<sub>10</sub></b-form-checkbox>
                        <b-form-checkbox value="O3">O<sub>3</sub></b-form-checkbox>
                        <b-form-checkbox value="CO">CO</b-form-checkbox>
                        <b-form-checkbox value="NO2">NO<sub>2</sub></b-form-checkbox>
                        <b-form-checkbox value="SO2">SO<sub>2</sub></b-form-checkbox>
                        <b-form-checkbox value="WS">{{ $i18n.locale === 'th' ? 'ความเร็วลม' : 'wind speed' }}</b-form-checkbox>
                        <b-form-checkbox value="WD">{{ $i18n.locale === 'th' ? 'ทิศทางลม' : 'wind direction' }}</b-form-checkbox>
                        <b-form-checkbox value="TEMP">{{ $i18n.locale === 'th' ? 'อุณหภูมิ' : 'temperature' }}</b-form-checkbox>
                        <b-form-checkbox value="RH">{{ $i18n.locale === 'th' ? 'ความชื้นสัมพัทธ์' : 'relative humidity' }}</b-form-checkbox>
                        <b-form-checkbox value="BP">{{ $i18n.locale === 'th' ? 'ความกดอากาศ' : 'barometric pressure' }}</b-form-checkbox>
                        <b-form-checkbox value="RAIN">{{ $i18n.locale === 'th' ? 'ปริมาณน้ำฝน' : 'rainfall amount' }}</b-form-checkbox>
                    </b-form-checkbox-group>
                </b-col>
            </b-row>          
            <!-- <b-form-select v-model="stationSelect" :options="stationList" style="margin-top:10px;" size="sm" multiple></b-form-select> -->
            <div style="margin-top:10px;">
                <b-row>
                    <b-col md="12" lg="4">
                        <div style="">
                            <b-row>
                                <b-col>
                                    {{$t('start_date')}}
                                    <datetime 
                                    type="date" 
                                    v-model="datetimeStart" 
                                    :title="$t('start_date')"
                                    value-zone="Asia/Bangkok"
                                    input-id="startDate"
                                    :min-datetime="minDate"
                                    :max-datetime="maxDate"
                                    >
                                    <!-- <label for="startDate" slot="before"><i class="far fa-calendar-alt"></i></label> -->
                                    </datetime>
                                </b-col>
                                <b-col>
                                    <b-form-select v-model="timeStart" :options="timeOption" size="sm" 
                                    style="margin-top:24px;border-color:black;border-width: 2px">
                                    </b-form-select>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                    <b-col md="12" lg="1" style="text-align:center;">
                        <div style="margin-left:5px;margin-right:5px;margin-top:26px;">
                            <div class="arrow-right">
                                <i class="fa fa-angle-right"></i>
                            </div>
                        </div>
                    </b-col>
                    <b-col md="12" lg="4">
                        <div style="">
                            <b-row>
                                <b-col>
                                    {{$t('end_date')}}
                                    <datetime                                         
                                    type="date" 
                                    v-model="datetimeEnd" 
                                    class="theme-orange" 
                                    :title="$t('end_date')"
                                    value-zone="Asia/Bangkok"
                                    input-id="endDate"
                                    :min-datetime="minDate"
                                    :max-datetime="maxDate"
                                    >
                                    <!-- <label for="endDate" slot="before"><i class="far fa-calendar-alt"></i></label> -->
                                    </datetime>
                                </b-col>
                                <b-col>
                                    <b-form-select v-model="timeEnd" :options="timeOption" size="sm"
                                    style="margin-top:24px;border-color:black;border-width: 2px">
                                    </b-form-select>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                    <b-col md="12" lg="3">
                        <div style="margin-top:24px;">
                            <b-button block variant="primary" size="sm" @click="serchData()">{{$t('examine')}} <i class="fa fa-arrow-right"></i></b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <!-- {{stationSelect}} -->
        </div>
        <hr>
        <div v-if="stationDatas.length <= 0" style="height:250px;position: relative;">
            <h1 style="color:gray;" class="center">{{$t('no_data')}}</h1>
        </div>
        <div v-else>
            <b-tabs content-class="mt-3">
                <!-- <b-tab :title="data == 'PM25' ? 'PM2.5' : textOptionName(data)" v-for="data,index of paramsTabs" :key="index"> -->
                <b-tab v-for="data,index of paramsTabs" :key="index">
                    <template #title>
                        <label v-html="textOptionName(data)"></label>
                    </template>
                    <div style="text-align:left">
                        <strong v-html="textOptionName(data)"></strong>
                    </div>
                    <div v-if="checkDrawChart(data)">
                        <div class="mt-3">
                            <Highcharts ref="highchartsRef" :options="chartOptionParam[index]">
                            </Highcharts>
                        </div>
                        <hr>
                    </div>
                    <b-table 
                    :bordered="true" 
                    :items="tabledatasParam[index]" 
                    :fields="tablefieldsParam[index]"
                    :current-page="currentPage[index]"
                    :per-page="20"
                    show-empty>
                        <template #empty>
                            <h4>{{$t('no_data')}}</h4>
                        </template>
                        <template #emptyfiltered="scope">
                            <h4>{{ scope.emptyFilteredText }}</h4>
                        </template>
                        <template #cell()="data">
                            <!-- {{ data.value }} -->
                            <i v-if="data.value != null">{{ data.value }}</i>
                            <i v-else>-</i>
                        </template>
                        <template #head()="data">
                            <span v-html="data.label"></span>
                        </template>
                    </b-table>
                    <b-row>
                        <b-col sm="12" md="6" lg="8">

                        </b-col>
                        <b-col sm="12" md="6" lg="4">
                            <b-pagination
                            v-model="currentPage[index]"
                            :total-rows="totalRows[index]"
                            :per-page="20"
                            align="fill"
                            size="sm"
                            class="my-0"
                            ></b-pagination>
                        </b-col>
                    </b-row>
                    <strong>Summary</strong>
                    <b-table 
                    :bordered="true" 
                    :items="tabledatasSum[index]" 
                    :fields="tablefieldsSum[index]"
                    show-empty>
                        <template #empty>
                            <h4>{{$t('no_data')}}</h4>
                        </template>
                        <template #cell()="data">
                            <i v-if="data.value != null && data.value != '-1'">{{ data.value }}</i>
                            <i v-else>-</i>
                        </template>
                    </b-table>
                </b-tab>
            </b-tabs>
            <!-- <b-tabs content-class="mt-3">
                <b-tab :title="data.stationID" v-for="data,index of stationDatas" :key="index">
                    <div style="text-align:left">
                        <strong>{{ getstationName(data.stationID) }}</strong>
                    </div>
                    <div class="mt-3">
                        <Highcharts ref="highchartsRef" :options="chartOption[index]">
                        </Highcharts>
                    </div>
                    <hr>
                    <b-table 
                    :bordered="true" 
                    :items="tabledatas[index]" 
                    :fields="tablefields[index]"
                    :current-page="currentPage[index]"
                    :per-page="20"
                    show-empty>
                        <template #empty>
                            <h4>{{$t('no_data')}}</h4>
                        </template>
                        <template #emptyfiltered="scope">
                            <h4>{{ scope.emptyFilteredText }}</h4>
                        </template>
                        <template #cell()="data">
                            <i v-if="data.value != null && data.value != ''">{{ data.value }}</i>
                            <i v-else>-</i>
                        </template>
                        <template #head()="data">
                            <span v-html="data.label"></span>
                        </template>
                    </b-table>
                    <b-row>
                        <b-col sm="12" md="6" lg="8">

                        </b-col>
                        <b-col sm="12" md="6" lg="4">
                            <b-pagination
                            v-model="currentPage[index]"
                            :total-rows="totalRows[index]"
                            :per-page="20"
                            align="fill"
                            size="sm"
                            class="my-0"
                            ></b-pagination>
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs> -->
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import { Datetime } from 'vue-datetime';

export default {
    name : 'history_hr',
    components : {
        Multiselect,
        datetime: Datetime
    },
    data() {
        return {
            minDate:null,
            maxDate:null,
            datetimeStart: null,
            timeStart:'00',
            datetimeEnd: null,
            timeEnd: '23',//moment().format('HH'),
            timeOption:[
                { value: '00', text: '00:00', },
                { value: '01', text: '01:00', },
                { value: '02', text: '02:00', },
                { value: '03', text: '03:00', },
                { value: '04', text: '04:00', },
                { value: '05', text: '05:00', },
                { value: '06', text: '06:00', },
                { value: '07', text: '07:00', },
                { value: '08', text: '08:00', },
                { value: '09', text: '09:00', },
                { value: '10', text: '10:00', },
                { value: '11', text: '11:00', },
                { value: '12', text: '12:00', },
                { value: '13', text: '13:00', },
                { value: '14', text: '14:00', },
                { value: '15', text: '15:00', },
                { value: '16', text: '16:00', },
                { value: '17', text: '17:00', },
                { value: '18', text: '18:00', },
                { value: '19', text: '19:00', },
                { value: '20', text: '20:00', },
                { value: '21', text: '21:00', },
                { value: '22', text: '22:00', },
                { value: '23', text: '23:00', }
            ],
            selectedType: [], // Must be an array reference!
            optionsType: [
                { text: 'PM<sub>2.5</sub>', value: 'PM25' },
                { text: 'PM<sub>10</sub>', value: 'PM10' },
                { text: 'O<sub>3</sub>', value: 'O3' },
                { text: 'CO', value: 'CO' },
                { text: 'NO<sub>2</sub>', value: 'NO2' },
                { text: 'SO<sub>2</sub>', value: 'SO2' },
                { text: 'ความเร็วลม', value: 'WS' },
                { text: 'ทิศทางลม', value: 'WD' },
                { text: 'อุณหภูมิ', value: 'TEMP' },
                { text: 'ความชื้นสัมพัทธ์', value: 'RH' },
                { text: 'ความกดอากาศ', value: 'BP' },
                { text: 'ปริมาณน้ำฝน', value: 'RAIN' },
            ],
            unit:{ 
                PM25:'<label>ug/m<sup>3</sup></label>',
                PM10:'<label>ug/m<sup>3</sup></label>' ,
                O3:'<label>ppb</label>' ,
                CO:'<label>ppm</label>' ,
                NO2:'<label>ppb</label>',
                SO2:'<label>ppb</label>',
                WS:'<label>m/s</label>',
                WD:'<label>Degree</label>',
                TEMP:'<label>°C</label>',
                RH:'<label>%</label>',
                BP:'<label>%</label>',
                RAIN:'<label>%</label>',
            },
            stationSelect: [],
            stationList: [],
            stationDatas:[],
            currentPage:[],
            totalRows:[],
            tablefields:[],
            tabledatas:[],
            chartOption: [],
            regions:[],
            select_region:null,
            paramsDatas:[],
            paramsTabs:[],
            chartOptionParam:[],
            tablefieldsParam:[],
            tabledatasParam:[],
            tablefieldsSum:[],
            tabledatasSum:[]
        }
    },
    methods : {
        checkDrawChart(name){
            if(name == 'WS' || name == 'WD' || name == 'TEMP' ||
               name == 'RH' || name == 'BP' || name == 'RAIN')
                return false

            return true
        },
        textOptionName(name){
            var r_text = name
            for(var a=0;a<this.optionsType.length;a++){
                if(this.optionsType[a].value == name){
                    r_text = this.optionsType[a].text
                    break
                }
            }
            return r_text
        },
        getstationName(sid){
            let select = this.stationSelect.find(el => el.value === sid)
            return select['text']
        },
        serchData(){
            // console.log("Start",this.datetimeStart)
            // console.log("End",this.datetimeEnd)
            // console.log('time ' + this.timeStart + " : " + this.timeEnd)
            this.paramsTabs = []
            if(this.selectedType.length <= 0){
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    title: 'Error!',
                    text: 'ไม่ได้เลือก Parameter ที่ต้องการดูข้อมูล',
                    icon: 'error'
                })
                return
            }
            if(this.stationSelect.length <= 0){
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    title: 'Error!',
                    text: 'ไม่ได้เลือก สถาณี ที่ต้องการดูข้อมูล',
                    icon: 'error'
                })
                return
            }
            var station_id = []
            for(var a=0;a<this.stationSelect.length;a++){
                station_id.push(this.stationSelect[a].value)
            }
            
            // var url = "http://air4thai.com/webV2/history/api/data.php?stationID="
            var url = "http://air4thai.com/forweb/getHistoryData.php?stationID="
            url += station_id + "&param=" + this.selectedType + "&type=hr"
            url += "&sdate=" + moment(this.datetimeStart).format('yyyy-MM-DD')
            url += "&edate=" + moment(this.datetimeEnd).format('yyyy-MM-DD')
            url += "&stime=" + this.timeStart
            url += "&etime=" + this.timeEnd

            // console.log("url",url)
            axios.get(url,{}).then((response) => {
                //console.log("Res " , response)
                // console.log('list',response.data.stations)
                this.stationDatas = response.data.stations
                // this.setDatasByParam(this.stationDatas)
                this.setChartDataParam(this.stationDatas)
                this.setTableDataParam(this.stationDatas)
                // this.setTableDatas(this.stationDatas)
                // this.setChartData(this.stationDatas)
            }).catch(err => {
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    title: 'Error!',
                    text: err,
                    icon: 'error'
                })
            })
        },
        setTableDatas(datas){
            this.tablefields = []
            this.tabledatas = []
            for(var a=0;a<datas.length;a++){
                var addFields = [{key: 'DATETIMEDATA',label: "วันที่เวลา" , class: 'tb_date'}]
                var haveParam = datas[a].params.length
                this.currentPage[a] = 1
                this.totalRows[a] = datas[a].data.length
                for(var b=0;b<haveParam;b++){
                    // console.log('field',datas[a].params[b])
                    addFields.push({
                        key:datas[a].params[b],
                        label:datas[a].params[b] + '<br><small>' + this.unit[datas[a].params[b]] + '</small>'})
                }
                this.tablefields.push(addFields)
                this.tabledatas.push(datas[a].data)
            }
        },
        setChartData(datas){
            // console.log("Chart datas " , datas)
            this.chartOption = []
            for(var a=0;a<datas.length;a++){
                var date_cate = []
                var series_data = []
                // ADD PARAMS
                for(var c=0;c<datas[a].params.length;c++){
                    series_data.push({
                        name : datas[a].params[c],
                        data : []
                    })
                }
                // ADD DATAS
                for(var b=0;b<datas[a].data.length;b++){
                    date_cate.push(datas[a].data[b]['DATETIMEDATA'])
                    for(var d=0;d<datas[a].params.length;d++){
                        for(var e=0;e<series_data.length;e++){
                            var pr = datas[a].params[d]
                            if(series_data[e].name == pr){
                                // if(datas[a].data[b][pr] != null)
                                series_data[e].data.push(datas[a].data[b][pr])
                            }
                        }
                    }
                }
                // console.log('series_data ',series_data)
                this.chartOption.push(
                {
                    chart: {
                        type: 'spline'
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: null
                    },
                    subtitle: {
                        text: null
                    },
                    xAxis: {
                        type: 'datetime',
                        categories: date_cate
                    },
                    yAxis: {
                        title: {
                            text: null
                        },
                    },
                    tooltip: {
                        formatter: function () {
                            var s = '<span style="font-size:10px">' + moment(this.x).add(543, 'year').format('DD MMM YYYY HH:mm') + ' น.</span>'
                            s += '<table>'
                            for(var a=0;a<this.points.length;a++){
                                var series_data = this.points[a]
                                var r_unit = '<small>ug/m<sup>3</sup></small>'
                                if(series_data.series.name == 'O3' || 
                                   series_data.series.name == 'NO2' || 
                                   series_data.series.name == 'SO2')
                                    r_unit = '<small>ppb</small>'
                                else if(series_data.series.name == 'CO')
                                    r_unit = '<small>ppm</small>'
                                s += '<tr><td style="color:' + series_data.color + ';padding:0">' + series_data.series.name +': </td>'
                                s += '<td style="padding:0">' + series_data.y + ' ' + r_unit + '</td></tr>'
                            }
                            s += '</table>'
                            return s
                        },
                        shared: true,
                        useHTML: true
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'center',
                        verticalAlign: 'bottom',
                        borderWidth: 0
                    },
                    series: series_data
                })
            }
        },
        change_selectRegion(){
            this.click_selectRegion(this.select_region)
        },
        click_selectRegion(id){
            // console.log('select',id)
            if(id == null){
                this.getStationList()
            }else{
                axios.get('http://air4thai.com/forweb/getAQI_JSON.php?region=' + id,{}).then((response) => {  
                    // console.log('response',response)
                    var have = response.data.stations.length
                    this.stationList = []
                    for(var a=0;a<have;a++){
                        this.stationList.push({
                            value: response.data.stations[a].stationID , 
                            text: response.data.stations[a].nameTH + " " + response.data.stations[a].areaTH,
                            textEN: response.data.stations[a].nameEN + " " + response.data.stations[a].areaEN
                        })
                    }
                }).catch(err => {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        title: 'Error!',
                        text: err,
                        icon: 'error'
                    })
                })
            }
        },
        getRegionList(){
            var list_url = 'http://air4thai.com/forweb/getList_JSON.php'
            axios.get(list_url,{}).then((response) => {
                //console.log('getList_JSON',response.data)
                this.regions = []//response.data.regions
                for(var b=0;b<response.data.regions.length;b++){
                    if(response.data.regions[b].regionID != '8'){
                        this.regions.push(response.data.regions[b]);
                    }
                }
                // var haveit = false;
                // for(var a=0;a<this.regions.length;a++){
                //     if(this.regions[a].regionID == this.$route.params.rid){
                //         haveit = true;
                //         break;
                //     }
                // }
                // if(haveit)
                //     this.click_selectRegion(this.$route.params.rid)
                // else
                this.select_region = null
                this.click_selectRegion(null)
            }).catch(err => {
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    title: 'Error!',
                    text: err,
                    icon: 'error'
                })
            })
        },
        getStationList(){
            axios.get('http://air4thai.com/forweb/getHistoryStation.php',{}).then((response) => {
                // console.log('list',response.data)
                var have = response.data.length
                this.stationList = []
                for(var a=0;a<have;a++){
                    this.stationList.push({
                        value: response.data[a].ID , 
                        text: response.data[a].Name ,
                        textEN: response.data[a].Name
                    })
                }
            }).catch(err => {
                this.$swal({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    title: 'Error!',
                    text: err,
                    icon: 'error'
                })
            })
        },
        setDatasByParam(datas){
            if(datas.length > 0){
                this.paramsTabs = datas[0].params
                this.paramsDatas = {}
                for (var a=0; a<this.selectedType.length; a++) {
                    var json_data = '{ "' + this.selectedType[a] + '" : [] }'
                    var jsonParam = JSON.parse(json_data)
                    this.paramsDatas = { ...this.paramsDatas ,  ...jsonParam }
                }
                //console.log('paramsDatas 1 : ',this.paramsDatas)
                for (var b=0;b<this.paramsTabs.length;b++) {
                    for (a=0;a<datas.length;a++) {
                        this.paramsDatas['PM25'].push({
                            station_id : '1234'
                        })
                    }
                }
                //console.log('paramsDatas 2 : ',this.paramsDatas)
                // for (a=0;a<datas.length;a++) {
                //     for (var b=0;b<this.paramsDatas.length;b++) {

                //     }
                // }
            }else{
                console.log("No Datas ...... !")
            }
        },
        setChartDataParam(datas){
            // console.log('datas ' , datas)
            this.chartOptionParam = []
            if(datas.length > 0){
                this.paramsTabs = datas[0].params
                var _params = datas[0].params
                for(var a=0;a<_params.length;a++){
                    var date_cate = []
                    var series_data = []
                    for(var b=0;b<datas.length;b++){
                        // Add Stataion //
                        series_data.push({
                            param: _params[a],
                            name : datas[b].stationID,
                            full_name : this.getstationName(datas[b].stationID),
                            data : []
                        })
                        // Add Datas //
                        for(var c=0;c<datas[b].data.length;c++){
                            date_cate.push(datas[b].data[c]['DATETIMEDATA'])
                            for(var d=0;d<series_data.length;d++){
                                if(series_data[d].name == datas[b].stationID){
                                    // if(datas[b].data[c][_params[a]] != null)
                                    series_data[d].data.push(datas[b].data[c][_params[a]])
                                }
                            }
                        }
                    }
                    //console.log('series_data ',series_data)
                    

                    this.chartOptionParam.push(
                    {
                        chart: {
                            type: 'spline'
                        },
                        credits: {
                            enabled: false
                        },
                        title: {
                            text: null
                        },
                        subtitle: {
                            text: null
                        },
                        xAxis: {
                            type: 'datetime',
                            categories: date_cate
                        },
                        yAxis: {
                            title: {
                                text: this.checkTitleChart(series_data[0].param) + '('+this.unit[series_data[0].param]+')'
                            },
                        },
                        tooltip: {
                            formatter: function () {
                                var s = '<span style="font-size:10px">' + moment(this.x).add(543, 'year').format('DD MMM YYYY HH:mm') + ' น.</span>'
                                s += '<table>'
                                for(var a=0;a<this.points.length;a++){
                                    var series_data = this.points[a]
                                    var r_unit = '<small>ug/m<sup>3</sup></small>'
                                    if(series_data.series.userOptions['param'] == 'O3' || 
                                    series_data.series.userOptions['param'] == 'NO2' || 
                                    series_data.series.userOptions['param'] == 'SO2')
                                        r_unit = '<small>ppb</small>'
                                    else if(series_data.series.userOptions['param'] == 'CO')
                                        r_unit = '<small>ppm</small>'
                                    s += '<tr><td style="color:' + series_data.color + ';padding:0">' + series_data.series.name +' : </td>'
                                    s += '<td style="padding:0">' + series_data.y + ' ' + r_unit + '</td></tr>'
                                }
                                s += '</table>'
                                return s
                            },
                            shared: true,
                            useHTML: true
                        },
                        legend: {
                            layout: 'vertical',
                            align: 'center',
                            verticalAlign: 'bottom',
                            borderWidth: 0,
                            labelFormatter: function() {
                                // console.log(this)
                                return this.name +' (' + this.userOptions.full_name + ')';
                            },
                        },
                        series: series_data
                    })
                }
            }
            // for(var a=0;a<datas.length;a++){
            //     var date_cate = []
            //     var series_data = []

            // }
        },
        setTableDataParam(datas){
            // console.log('table ',datas)
            this.tablefieldsParam = []
            this.tabledatasParam = []
            this.tablefieldsSum = []
            this.tabledatasSum = []

            if(datas.length > 0){
                var _params = datas[0].params
                for(var a=0;a<_params.length;a++){
                    
                    var addFields = [
                        {key: 'no',label: 'No.'},
                        {key: 'DATEDATA',label: "วันที่" , class: 'tb_date'},
                        {key: 'TIMEDATA',label: "ช่วงเวลา" , class: 'tb_date'}
                    ]
                    var addSumFields = [{key:'title',label:''}]
                    for(var b=0;b<datas.length;b++){
                        addFields.push({
                            key:datas[b].stationID,
                            label:datas[b].stationID
                        })
                        addSumFields.push({
                            key:datas[b].stationID,
                            label:datas[b].stationID
                        })
                    }
                    // Add Data //
                    var t_datas = []
                    for(var c=0;c<datas[0].data.length;c++){
                        var t_data = {
                            no : (c + 1) ,
                            DATEDATA : moment(datas[0].data[c]['DATETIMEDATA']).format("YYYY-MM-DD"),
                            // TIMEDATA : moment(datas[0].data[c]['DATETIMEDATA']).format("HH:mm"),
                            // TIMEDATA : moment(datas[0].data[c]['DATETIMEDATA']).format("HH:mm") + " - " + moment(datas[0].data[c]['DATETIMEDATA']).add(1, 'hours').format("HH:mm"),
                            TIMEDATA: moment(datas[0].data[c]['DATETIMEDATA']).add(1, 'hours').format("HH:mm"),
                        }
                        var json_data = '{ '
                        for(var d=0;d<datas.length;d++){
                            json_data += '"' + datas[d].stationID + '":' + datas[d].data[c][_params[a]]
                            if(d < datas.length - 1)
                            json_data += ','
                        }
                        json_data += '}'
                        var t2_data = JSON.parse(json_data)
                        // console.log('t2',t2_data)
                        t_datas.push({
                            ...t_data , ...t2_data
                        })
                    }
                    // console.log('sum',datas[0].summary)
                    var sum_title = [
                        'ค่าสูงสุด',
                        'ค่าน้อยสุด',
                        'ค่าเฉลี่ย',
                        'จำนวนที่เก็บค่าได้',
                        'จำนวนชั่วโมงทั้งหมด',
                        'เปอร์เซ็นต์การเก็บค่าได้'
                    ]
                    var sum_key = [
                        'max',
                        'min',
                        'average',
                        'count',
                        'totalcount',
                        'countPercentage'
                    ]
                    var add_sum_datas = []
                    // var s1_data ={}
                    // for(var s=0;s<sum_title.length;s++){
                        
                    // }
                    
                    for(var k=0;k<sum_key.length;k++){
                        var json_data_sum = '{ "title":"' + sum_title[k] +'",'
                        for(var sd=0;sd<datas.length;sd++){
                            // console.log(datas[sd].stationID)
                            // console.log('sum ',datas[sd].summary[_params[a]][sum_key[k]])
                            json_data_sum += '"' + datas[sd].stationID + '":' + datas[sd].summary[_params[a]][sum_key[k]]
                            if(sd < datas.length - 1)
                            json_data_sum += ','
                        }
                        json_data_sum += '}'
                        var s2_data = JSON.parse(json_data_sum)
                        add_sum_datas.push(
                            s2_data
                        )
                    }

                    this.currentPage[a] = 1
                    this.totalRows[a] = t_datas.length
                    this.tablefieldsParam.push(addFields)
                    this.tabledatasParam.push(t_datas)
                    this.tablefieldsSum.push(addSumFields)
                    this.tabledatasSum.push(add_sum_datas)
                }
            }
        },
        checkTitleChart(title){
            if(title == 'PM25' || title == 'PM10' || title == 'O3' ||
               title == 'CO' || title == 'NO2' || title == 'SO2')
                return 'ความเข้มข้น '
            return ''
        }
    },
    created() {
        var now = new Date();
        console.log(now);
        this.datetimeStart = moment().add(-1, 'day').format('yyyy-MM-DDTHH:mm:ss')
        this.datetimeEnd = moment().format('yyyy-MM-DDTHH:mm:ss')
        console.log("dstart :", this.datetimeStart);
        console.log("dend :", this.datetimeEnd);
        this.maxDate = moment().add(1, 'day').format('yyyy-MM-DDTHH:mm:ss')
        now.setDate(now.getDate() - 30);
        this.minDate = moment(now).format('yyyy-MM-DDTHH:mm:ss')
    },
    mounted() {
        this.getRegionList()
        // this.getStationList()
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
// @import '/node_modules/vue-datetime/dist/vue-datetime.min.css';


.center {
  margin: 0;
  position: absolute;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
}
.tb_date{
    text-align: left;
}
.data_group{
    text-align: left;
    background: rgba(1, 111, 183, 0.05);
    border-radius: 5px;
    padding:20px;
}

// .vdatetime input {
//   border-color: red !important;
// }
// .vdatetime {
//   border-color: red !important;
// }
// .vdatetime-input input {
//   border-color: red !important;
// }

// .date-class{
//     width: 100% !important;
//     color: red;
//     border-color: red !important;
// }
@media only screen and (max-width : 992px) {
    .arrow-right{
        position: relative;
        transition: 200ms;
        transition-timing-function: ease-in-out;
        transform: rotate(90deg);
    }
}
@media only screen and (min-width : 992px) {
    .arrow-right{
        position: relative;
        transition: 500ms;
        transition-timing-function: ease-in-out;
        transform: rotate(0deg);
    }
}
.list_select{
    margin-top:0px;
    color: #016FB7;
}
.region_select{
    background: rgba(1, 111, 183, 0.0);
    color: #016FB7;
    border-width: 0px;
}
.multi_style{
    background: rgba(1, 111, 183, 0.0);
    color: #016FB7;
    border-width: 0px;
}
.custom__tag{
    background: red;
    margin: 5px;
}
</style>
<style>
.vdatetime {
    width: 100%;
}
.vdatetime-input {
  /* border-color: red !important; */
  border-radius: 5px;
  width: 100%;
}
</style>
<style>

.multiselect.invalid .multiselect__tags {
  border: 1px solid #f86c6b !important;
}
.multiselect__option--highlight {
  background: #428bca !important;
}

.multiselect__option--highlight:after {
  background: #428bca !important;
}

.multiselect__tag {
  background: #016FB7 !important;
  border: 1px solid #016FB7 !important;
  color: white !important;
  margin-bottom: 0px !important;
  margin-right: 5px !important;
}
.multiselect__tag-icon:after {
  color: red !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #ffffff !important;
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: red !important;
}
.multiselect * {
    /* border-width: 0px; */
    /* background: rgba(241, 247, 250, 1.0); */
    /* margin-left:0px; */
    /* padding-left:0px; */
}

</style>