<template>
    <div>
        <div style="text-align: left;">
            <div style="width:100%;height:60px;">
                <div class="select_year">
                    <i class="fas fa-search"></i>
                    <b-form-select v-model="yearSelect" :options="yearDatas" class="mb-3" @change="selectYear()">
                    </b-form-select>
                </div>
            </div>
            <div>
                <b-tabs content-class="mt-3" style="text-align:center;">
                    <b-tab :title="data.region_title" v-for="data,index of yearSelectData" :key="index">
                        <b-table stacked="md" :bordered="false" :items="tabledatas[index]" :fields="tablefields"
                            show-empty style="text-align:center;">
                            <template #empty>
                                <div style="text-align:center;">
                                    <h4>ไม่มีข้อมูล</h4>
                                </div>
                            </template>
                            <template #emptyfiltered="scope">
                                <h4>{{ scope.emptyFilteredText }}</h4>
                            </template>
                            <template #cell(station)="data">
                                <div style="text-align:left;">
                                    {{ data.value }}
                                </div>
                            </template>
                            <template #cell()="data">
                                <!-- {{ data.value }} -->

                                <div class="pdf_icon_gray" v-if="data.value == 'x'">
                                    <!-- <i class="fa fa-file"></i> -->
                                    <i class="fas fa-file-pdf"></i>
                                    <!-- <b-icon icon="x-circle" scale="2" variant="danger"></b-icon> -->
                                </div>
                                <div class="pdf_icon" v-else>
                                    <!-- <i class="fa fa-file" @click="loadpdf(data.value)"></i> -->
                                    <i class="fas fa-file-pdf" @click="loadpdf(data.value)"></i>
                                </div>
                            </template>
                        </b-table>
                    </b-tab>
                </b-tabs>
            </div>
            {{yearDatas[this.yearSelect]['monthly']}}
        </div>
    </div>
</template>
<script>

export default {
    name: 'history_month',
    components: {
    },
    props : {
        reportList : {
            type: Array
        }
    },
    data () {
        return {
            yearSelect:'',
            yearDatas:[],
            tabledatas:[],
            tablefields:[]
        }
    },
    computed : {
        yearSelectData(){
            return this.reportList[this.yearSelect]['monthly']
        }
    },
    methods : {
        loadpdf(filename){
            //console.log("File name ",filename)
            var url = "http://air4thai.com/forweb/getReport.php?file=" + filename
            window.open(url);
        },
        setYearDatas(){
            var have = this.reportList.length
            this.yearDatas = []
            this.yearSelect = 0
            for(var a=0;a<have;a++){
                this.yearDatas.push(
                    {value: a, text: this.reportList[a].gtitle}
                )
            }
            this.selectYear()
        },
        selectYear(){
            var yearEnd = this.reportList[this.yearSelect]['yend']
            var haveM = this.reportList[this.yearSelect]['monthly'].length
            this.tablefields = [{
                    key:"station",
                    label:"สรุปข้อมูลรายเดือน (สถานี)"
            }]
            this.tabledatas = []
            for(var a=0;a<haveM;a++){
                var haveStations = this.reportList[this.yearSelect]['monthly'][a].stations.length
                var stationList = []
                for(var b=0;b<haveStations;b++){
                    var stationName = '{"station":' + '"' + this.reportList[this.yearSelect]['monthly'][a].stations[b].title + '"'
                    for(var c=0;c<7;c++){
                        var yearname = "" + (yearEnd - c)
                        var fileName = this.reportList[this.yearSelect]['monthly'][a].stations[b].prefix
                        fileName = fileName + yearname
                        if(this.reportList[this.yearSelect]['monthly'][a].stations[b].suffix != null)
                            fileName = fileName + "_" + this.reportList[this.yearSelect]['monthly'][a].stations[b].suffix
                        if(parseInt(yearname) < 1996)
                            fileName = 'x'
                        stationName += ',"' + yearname + '":' + '"' + fileName + '"'
                    }
                    stationName += '}'
                    stationList.push(JSON.parse(stationName))
                }
                this.tabledatas.push(stationList)
            }
            for(var x=0;x<7;x++){
                this.tablefields.push({key:""+(yearEnd - x) ,label:""+(yearEnd - x + 543) })
            }
            // Check Have File //
            for(var y=0;y<this.tabledatas.length;y++){
                // console.log("Key Name : " ,  this.tabledatas[y])
                for(var z=0;z<this.tabledatas[y].length;z++){
                    var keys = Object.keys(this.tabledatas[y][z])
                    for(var z2=0;z2<keys.length;z2++){
                        if(keys[z2] != "station"){
                            var _fileName = this.tabledatas[y][z][keys[z2]]
                            var keyName = keys[z2]
                            // Check Have //
                            this.checkHaveFile(_fileName,y,z,keyName)
                        }
                    }
                }
            }
            
        },
        async checkHaveFile(_fileName, index1, index2, keyName){
            const response = await fetch('http://air4thai.com/forweb/getExists.php?filename=' + _fileName)
            const datas = await response.json()
            if(datas == 0){
                this.tabledatas[index1][index2][keyName] = 'x'
            }
        }
    },
    created () {
        this.setYearDatas()
    }
}
</script>

<style lang="scss" scoped>
.select_year{
    display: flex;
    width: 220px;
    float: right;
    i {
        display: inline;
        font-size:22px;
        margin-top:5px;
        margin-right: 10px;
    }
}
.pdf_icon{
    text-align: center;
    i {
        font-size: 22px;
        color: rgb(255, 0, 0);
        cursor: pointer;
        transition: 200ms;
        transition-timing-function: ease-in-out;
    }
}
.pdf_icon_gray{
    text-align: center;
    i {
        font-size: 22px;
        color: gray;
        cursor: not-allowed;
        // transition: 200ms;
        // transition-timing-function: ease-in-out;
    }
}
.pdf_icon i:hover{
    transform: rotate(20deg) scale(1.2,1.2);
}
</style>