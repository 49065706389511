<template>
    <div>
        <div style="text-align: left;">
            <div style="width:100%;height:60px;">
                <div class="select_year" >
                    <i class="fa fa-search"></i>
                    <b-form-select v-model="yearSelect" :options="yearDatas" class="mb-3" @change="selectYear()">
                    </b-form-select>
                </div>
            </div>
            <div>
                <b-table  
                stacked="md"
                :bordered="false" 
                :items="tabledatas" 
                :fields="tablefields"
                show-empty
                style="text-align:center;">
                    <template #empty>
                        <div style="text-align:center;">
                            <h4>ไม่มีข้อมูล</h4>
                        </div>
                    </template>
                    <template #emptyfiltered="scope">
                        <h4>{{ scope.emptyFilteredText }}</h4>
                    </template>
                    <template #cell(station)="data">
                        <div style="text-align:left;">
                        {{ data.value }}
                        </div>
                    </template>
                    <template #cell()="data">
                        <div class="pdf_icon_gray" v-if="data.value == 'x'">
                            <i class="far fa-file-pdf"></i>
                        </div>
                        <div class="pdf_icon" v-else>
                            <i class="far fa-file-pdf" @click="loadpdf(data.value)"></i>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'history_year',
    props : {
        reportList : {
            type: Array
        }
    },
    data () {
        return {
            yearSelect:'',
            yearDatas:[],
            tabledatas:[],
            tablefields:{}
        }
    },
    methods : {
        loadpdf(filename){
            var url = "http://air4thai.com/forweb/getReport.php?file=" + filename
            window.open(url);
        },
        setYearDatas(){
            var have = this.reportList.length
            this.yearDatas = []
            this.yearSelect = 0
            for(var a=0;a<have;a++){
                this.yearDatas.push(
                    {value: a, text: this.reportList[a].gtitle}
                )
            }
            this.selectYear()
        },
        selectYear(){
            //console.log('select',this.reportList[this.yearSelect])
            var yearEnd = this.reportList[this.yearSelect]['yend']
            var haveY = this.reportList[this.yearSelect]['yearly'].length
            this.tablefields = [{
                    key:"station",
                    label:"สรุปข้อมูลรายปี"
            }]
            for(var x=0;x<7;x++){
                this.tablefields.push({key:""+(yearEnd - x) ,label:""+(yearEnd - x + 543) })
            }
            this.tabledatas = []
            for(var a=0;a<haveY;a++){
                var stationName = '{"station":' + '"' + this.reportList[this.yearSelect]['yearly'][a].title + '"'
                for(var c=0;c<7;c++){
                    var yearname = "" + (yearEnd - c)
                    var fileName = this.reportList[this.yearSelect]['yearly'][a].prefix + yearname
                    stationName += ',"' + yearname + '":' + '"' + fileName + '"'
                }
                stationName += '}'
                this.tabledatas.push(JSON.parse(stationName))
            }

            for(var y=0;y<this.tabledatas.length;y++){
                var keys = Object.keys(this.tabledatas[y])
                for(var z2=0;z2<keys.length;z2++){
                    if(keys[z2] != "station"){
                        var _fileName = this.tabledatas[y][keys[z2]]
                        var keyName = keys[z2]
                        // Check Have //
                        this.checkHaveFile(_fileName,y,keyName)
                    }
                }
            }
        },
        async checkHaveFile(_fileName, index, keyName){
            const response = await fetch('http://air4thai.com/forweb/getExists.php?filename=' + _fileName)
            const datas = await response.json()
            if(datas == 0){
                this.tabledatas[index][keyName] = 'x'
            }
        }
    },
    created () {
        this.setYearDatas()
    }

}
</script>

<style lang="scss" scoped>
.select_year{
    display: flex;
    width: 220px;
    float: right;
    i {
        display: inline;
        font-size:22px;
        margin-top:5px;
        margin-right: 10px;
    }
}
.pdf_icon{
    text-align: center;
    i {
        font-size: 22px;
        color: rgb(255, 0, 0);
        cursor: pointer;
        transition: 200ms;
        transition-timing-function: ease-in-out;
    }
}
.pdf_icon_gray{
    text-align: center;
    i {
        font-size: 22px;
        color: gray;
        cursor: not-allowed;
        // transition: 200ms;
        // transition-timing-function: ease-in-out;
    }
}
.pdf_icon i:hover{
    transform: rotate(20deg) scale(1.2,1.2);
}
</style>